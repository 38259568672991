import React from 'react';
import { Select } from 'antd';

const SelectComponent = (props) => {
  const { Option } = Select;

  const children = [];
  return (
    <div className={props.className}>
      <label htmlFor={props.label}>
        <span style={{ color: 'red' }}> * </span> {props.label} :
      </label>
      <div className="ant_form">
        <Select
        virtual={false}
          value={props.selectedServiceGroup}
          mode={props.mode}
          allowClear
          disabled={props.disabled}
          style={{ width: '100%' }}
          placeholder={props.placeholder}
          onChange={props.handleChange}
        >
          {props.serviceGroup &&
            props.serviceGroup.map((item) => {
              return (
                <Select.Option
                  key={item.param_value}
                  value={item.param_value}
                  key={item.param_value}
                >
                  {item.param_description}
                </Select.Option>
              );
            })}
        </Select>
      </div>
    </div>
  );
};
export default SelectComponent;
